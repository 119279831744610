import { FC, useContext, useMemo } from "react";
import Link from "next/link";
import { formatAppNameText, WalletLayoutEnum } from "@finbackoffice/fe-core";
import { useHost, useRuntimeConfig } from "@finbackoffice/site-core";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Translate from "components/base/translate/Translate";
import OddsFormatSelector from "components/footer/odds-format-selector/OddsFormatSelector";
import LicenseLogo from "components/footer/license-logo/LicenseLogo";
import Img from "components/base/img/Img";
import { Svg } from "components/base/svg/Svg";
import { FooterContext } from "components/footer/Footer";
import styles from "./default.module.sass";

const isProduction = NODE_ENV === "production";

const Default: FC = () => {
    const { origin } = useHost();
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const VERSION = useRuntimeConfig("VERSION");
    const {
        enabledBonusesConfig,
        enabledPoliciesConfig,
        renderBonusesLink,
        renderPromotionsLink,
        renderPoliciesLink,
        renderSportLinks,
        renderCasinoLinks,
        renderSocialLinks,
    } = useContext(FooterContext);

    const renderFooterCurrenciesSection = useMemo(() => {
        if (COMMON_SITE_CONFIGS.wallet.type === WalletLayoutEnum.Pix) {
            return <Svg src="/common/footer/pix.svg" wrapper="span" className="svg-icon" />;
        }

        return (
            <>
                <Svg src="/common/footer/bitcoin.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/ripple.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/dogecoin.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/litecoin.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/tron.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/usdt.svg" wrapper="span" className="svg-icon" />
                <Svg src="/common/footer/ethereum.svg" wrapper="span" className="svg-icon" />
            </>
        );
    }, [COMMON_SITE_CONFIGS.wallet.type]);

    return (
        <FadeInAnimation>
            <footer className={styles.footer} data-testid="footer">
                <div className={styles.footerLinks}>
                    <nav>
                        <h2>
                            <Translate tid="footer_sports" />
                        </h2>
                        {renderSportLinks}
                    </nav>
                    <nav>
                        <h2>
                            <Translate tid="footer_casino" />
                        </h2>
                        {renderCasinoLinks}
                    </nav>
                    <nav>
                        <h2>
                            <Translate tid="footer_bonuses" />
                        </h2>
                        {renderPromotionsLink}
                        {enabledBonusesConfig.map(renderBonusesLink)}
                    </nav>
                    <nav>
                        <h2>
                            <Translate tid="footer_help_center" />
                        </h2>
                        {enabledPoliciesConfig.map(renderPoliciesLink)}
                    </nav>
                    <nav>
                        <h2>
                            <Translate tid="footer_odds" />
                        </h2>
                        <OddsFormatSelector />
                        <Link
                            href={
                                isProduction ? `${origin.replace("https://", "https://m.")}` : ""
                            }>
                            <Translate tid="footer_mobile" />
                        </Link>
                    </nav>
                </div>
                {COMMON_SITE_CONFIGS.wallet.type !== WalletLayoutEnum.Tola && (
                    <div className={styles.footerCurrencyLogos}>
                        {renderFooterCurrenciesSection}
                    </div>
                )}

                <div className={styles.socialLinksContainer}>
                    <span className={styles.age}>18+</span>
                    <nav>{renderSocialLinks}</nav>
                </div>
                {COMMON_SITE_CONFIGS.license.enable && (
                    <ErrorBoundary name={LicenseLogo.name}>
                        <LicenseLogo />
                    </ErrorBoundary>
                )}
                <Translate
                    tid="footer_copyright_text"
                    dangerous
                    replace={{ app_name: COMMON_SITE_CONFIGS.appName }}>
                    <div className={styles.footerText} />
                </Translate>

                <div className={styles.footerLogo}>
                    <Img
                        source={`${ASSETS_URL}/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/desktop/logo.svg`}
                        alt={`${COMMON_SITE_CONFIGS.appName} logo`}
                        title={`${COMMON_SITE_CONFIGS.appName} logo`}
                        width={0}
                        height={0}
                        style={{ width: "100%", height: "auto" }}
                    />
                    <h1>
                        <Translate
                            tid="footer_headText"
                            replace={{ app_name: COMMON_SITE_CONFIGS.appName }}
                        />
                    </h1>
                </div>
                <div className={styles.websiteTitle}>
                    {`© ${new Date().getFullYear()} ${COMMON_SITE_CONFIGS.appName}`}
                </div>
                <span className={styles.version}>
                    <Translate tid="footer_version" replace={{ version: VERSION }} />
                </span>
            </footer>
        </FadeInAnimation>
    );
};

export default Default;
